<template>
  <DatePicker v-model="dates" :input-debounce="500" is-range>
    <template v-slot="{ inputValue, inputEvents }">
      <div class="relative sm:w-24 2xl:w-32">
        <input
          name="from"
          placeholder="Desde"
          class="appearance-none pl-1 rounded-full border border-gray-500 border-b py-2 w-full bg-white 
          text-xs 2xl:text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 
          focus:text-gray-700 focus:outline-none"
          :value="inputValue.start"
          v-on="inputEvents.start"
          title="Selecciona un rango de fechas para filtrar los registros."
        />
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <button
            @click="()=>{this.dates = null}"
          >
            <faIcon icon="broom" size="xs" type="fas"/>
          </button>
        </span>
      </div>
      <div class="relative sm:w-24 2xl:w-32">
        <input
          name="from"
          placeholder="Hasta"
          class="appearance-none pl-1 rounded-full border border-gray-500 border-b py-2 w-full bg-white 
          text-xs 2xl:text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 
          focus:text-gray-700 focus:outline-none"
          :value="inputValue.end"
          v-on="inputEvents.end"
        />
      </div>
    </template>
  </DatePicker>
</template>
<script>
import moment from "moment"
import { DatePicker } from "v-calendar"

export default {
  name: "DateRange",
  data() {
    let start = ""
    let end = ""
    if (this.initialDates) {
      start = this.initialDates.start
      end = this.initialDates.end
    }
    return {
      dates: {
        start: start,
        end: end
      }
    }
  },
  methods: {
    emitDates() {
      let payload = null

      if(this.dates) {
        const dates = {
          start: moment(this.dates.start, "MM-DD-YYYY"),
          end: moment(this.dates.end, "MM-DD-YYYY")
        }
        payload = {
          start: moment(dates.start).format("YYYY-MM-DD"),
          end: moment(dates.end).format("YYYY-MM-DD")
        }
      }
      
      this.$emit('dates-set', payload)
    }
  },
  watch: {
    dates: {
      handler() {
        this.emitDates()
      },
      deep: true
    }

  },
  props: {
    initialDates: {
      type: Object,
      default: null
    }
  },
  components: {
      DatePicker
  }
}
</script>